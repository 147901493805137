<template>
  <div class="star">
    <!-- prevent拦截默认右键事件, self只有当前元素右键时, 触发onContextMenu -->
    <div
      class="star-content"
      @contextmenu.prevent="(e) => onContextMenu(e, 'contextContent')"
      ref="container"
    >
      <div class="star-header-top">
        <a-dropdown class="titles">
          <div
            class="ant-dropdown-link font18"
            @click="(e) => e.preventDefault()"
          >
            <span>{{ team.teamName }} </span>
            <h-icon iconName="set" className="set-icon" />
          </div>

          <a-menu slot="overlay" v-if="team.isStaff">
            <a-menu-item
              v-for="item in actionsForDefault[team.role]"
              @click="handleAction(item.actionCode, team)"
            >
              <a
                :class="{
                  danger:
                    item.title === '退出团队' || item.title === '解散团队',
                }"
              >
                {{ item.title }}</a
              >
            </a-menu-item>
          </a-menu>

          <a-menu slot="overlay" v-else>
            <a-menu-item
              v-for="item in actions[team.role]"
              :key="item.actionCode"
              @click="handleAction(item.actionCode, team)"
            >
              <a
                :class="{
                  danger:
                    item.title === '退出团队' || item.title === '解散团队',
                }"
              >
                {{ item.title }}</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <div
          class="star-header-top"
          style="justify-content: space-between; margin-top: 1rem"
        >
          <!-- tabs -->
          <!-- @handleClick点击某一项tab事件 -->
          <div></div>
          <!-- 右上角菜单 -->
          <div class="right-top" style="display: flex">
            <!-- v-if="flase" -->
            <a-button
              class="font-size"
              style="margin-right: 10px"
              ghost
              icon="font-size"
              type="primary"
              block
              @click="createMdFile()"
              >创建文档</a-button
            >
            <a-button
              class="menu-button-plus"
              style="margin-right: 10px"
              ghost
              icon="plus"
              type="primary"
              block
              @click="handleAddFile('folder')"
              >新建文件夹</a-button
            >
            <a-button
              v-if="isAudit == 1"
              class="menu-button-upload"
              icon="upload"
              type="primary"
              block
              @click="handleUpload"
              >上传文件</a-button
            >
            <a-dropdown v-else :trigger="['click']">
              <a-button
                class="menu-button-upload"
                icon="upload"
                type="primary"
                block
                >上传文件</a-button
              >
              <!-- v-if="functionalCheck(item.functionCode)" -->
              <a-menu slot="overlay" class="button-dropdown">
                <template v-for="item in UploaderList">
                  <a-menu-item :key="item.title">
                    <div class="uploader-modal-list-item" @click="item.emit">
                      <svg
                        class="icon"
                        style="
                          margin-right: 16px;
                          cursor: pointer;
                          font-size: 18px;
                        "
                        aria-hidden="true"
                      >
                        <use :xlink:href="`#icon-${item.icon}`"></use>
                      </svg>
                      <span>{{ item.title }}</span>
                    </div>
                  </a-menu-item>
                </template>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>

      <div class="star-header-top breadcrumb">
        <img
          v-show="breadcrumbList.length > 1"
          src="@/assets/icon/retreat.png"
          alt=""
          @click="toPreLevel"
        />
        <img
          v-show="breadcrumbList.length > 1"
          @click="toPreEnter"
          style="margin: auto 18px auto 12px"
          src="@/assets/icon/forward.png"
          alt=""
        />

        <!-- 面包屑, 根目录不展示 -->
        <a-breadcrumb class="star-header-top-breadcrumb" separator="">
          <!-- 面包屑前面加逐级返回选项 -->
          <!-- <a-breadcrumb-item class="breadcrumb-back" style="cursor: pointer"><span @click="toPreLevel">返回上一级</span>
              <span class="star-header-top-breadcrumb-separator">|</span>
            </a-breadcrumb-item> -->
          <!-- 面包屑真实路径 -->
          <a-breadcrumb-item
            style="cursor: pointer"
            v-for="(el, i) in breadcrumbList"
            :key="el.id"
            class="hover-effects"
            ><span
              @click="jumpToFolder(el, i)"
              :class="
                i === breadcrumbList.length - 1 && breadcrumbList.length !== 0
                  ? 'ckeckbread'
                  : ''
              "
              >{{ el.text }}</span
            >
            <!-- 面包屑结尾加斜线标志, separator自维护 -->
            <span
              v-show="
                breadcrumbList.length > 1 && i < breadcrumbList.length - 1
              "
              class="star-header-top-breadcrumb-separator"
              >/</span
            >
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="star-header-top">
        <!-- 总览, 全选 -->
        <div>
          <a-checkbox
            style="text-align: left"
            :indeterminate="indeterminate"
            v-model="checkIdListFlag"
            @change="onCheckAllChange"
          >
            已选{{ checkIdList.length }}个
          </a-checkbox>
          <a-dropdown v-if="checkIdList.length">
            <a-button>
              分享
              <a-icon type="down" />
            </a-button>

            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="#" @click="handleClick({ func: 'publicShareModal' })"
                    >公开分享</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="#"
                    @click="handleClick({ func: 'privacyShareModal' })"
                    >私密分享</a
                  >
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-button
            style="margin-left: 10px"
            v-for="(item, index) in checkIdList.length ? file_option : []"
            :key="index"
            @click="item.onClick"
            >{{ item.label }}</a-button
          >
        </div>
        <!-- 列表排序条件 -->
        <div class="star-header-top-right">
          <!--@handleClick点击menu项 -->
          <!-- 排序规则 -->
          <MenuSimple
            :list="topFilterList"
            :checkedList="[...sortIndicatorList, ...sortRuleList]"
            @handleClick="handleClick"
          />
          <!-- 切换视图 -->
          <MenuSimple
            :list="conversionModeList"
            :checkedList="[...conversionAction]"
            @handleClick="handleClick"
          />
          <div class="recycle click" @click="handleRecycle">
            <span class="font12"> 回收站 </span>
          </div>

          <!-- v-if="functionalCheck('DETAIL_FILE')" -->
          <div
            class="fileDetails click"
            @click="handleDetail"
            style="line-height: 32px"
          >
            <h-icon
              className="star-header-top-icon"
              iconName="fileDetails"
            ></h-icon>
            <span class="font12 gary-font"> 文件信息 </span>
          </div>
        </div>
      </div>

      <!-- 文件列表 -->
      <div
        class="star-content-fileContainer"
        ref="starContent"
        @scroll="onFileScroll"
      >
        <a-spin :spinning="wrapLoading">
          <!-- 展示table形式 -->
          <!-- @checkListItem 选中文件改变时触发, 返回所有文件id -->
          <FileList
            v-if="showTable === 'liebiaoshitu'"
            :listData="listData"
            :checkIdList="checkIdList"
            :pagination="false"
            @checkListItem="(arr) => flSetCheckIdList(arr)"
            @checkItem="toCheckItem"
            @dbCheckItem="toDblclickItem"
            @contextMenu="(e, el) => onContextMenu(e, 'fileContextContent', el)"
          />
          <!-- 展示平铺文件夹形式 -->
          <!-- @checkItem单击文件 / @dbCheckItem双击文件 / @menuClick点击文件菜单 / @sendMousedown文件拖拽操作 -->
          <TileList
            @upload="handleUpload"
            v-else
            :checkIdList="checkIdList"
            :listData="listData"
            @checkItem="toCheckItem"
            @dbCheckItem="toDblclickItem"
            @menuClick="handleClick"
            @sendMousedown="handleMousedown"
            @contextMenu="(e, el) => onContextMenu(e, 'fileContextContent', el)"
          />
        </a-spin>
      </div>

      <!-- 右键菜单 -->
      <!-- @handleClick点击右键菜单 -->
      <div class="star-content-contextmenu" ref="contextContent">
        <MydocMenu
          :checkedFileItemList="checkedFileItemList"
          :moduleType="moduleType"
          type="create"
          @handleClick="handleClick"
        />
      </div>

      <!-- 文件右键菜单 -->
      <!-- @handleClick点击右键菜单 -->
      <div
        class="star-content-contextmenu star-content-contextmenu-simple"
        ref="fileContextContent"
        @contextmenu.stop.prevent="() => {}"
      >
        <MydocMenu
          :checkedFileItemList="checkedFileItemList"
          :moduleType="moduleType"
          :type="checkIdList.length > 1 ? 'teamNormal' : 'teamFull'"
          ref="fileMenu"
          @handleClick="handleClick"
        />
      </div>

      <!-- 弹窗 -->
      <!-- visible控制弹窗展示 / popupType弹窗类型 / options其他参数 / @close弹窗关闭事件 -->
      <Popup
        :visible="visible"
        :moduleType="moduleType"
        :popupType="popupType"
        :options="popupOptions"
        @close="onPopupClose"
      />

      <!-- 图片预览 -->
      <PreviewImg
        :imgs="previewImgList"
        :showId="previewId"
        @previewHide="previewHide"
      />

      <!-- 协作 -->
      <CooperationModal
        v-if="cooperationVisible"
        :visible="cooperationVisible"
        :loading="cooperationLoading"
        :type="cooperationType"
        :file="checkFile"
        @close="cooperationVisible = false"
      />

      <!-- 分享 -->
      <ShareModal
        v-if="shareVisible"
        :visible="shareVisible"
        :loading="shareLoading"
        :type="shareType"
        :shareKey="shareKey"
        @close="shareVisible = false"
        @createShare="handleExpire"
      />

      <ShareFileModal
        v-if="shareFileVisible"
        :visible="shareFileVisible"
        :type="shareType"
        :fileList="shareFiles"
        @close="shareFileVisible = false"
        @publicOk="shareFileOk"
        @privateOk="privateShareFileOk"
      />
    </div>
    <div class="file-detail" v-if="detailShow">
      <FileDetails
        @sendClose="detailShow = false"
        :root="breadcrumbList[0]"
        :file="showFileDetail"
        :moduleType="moduleType"
      />
    </div>

    <TeamDynamic v-if="dynamicShow" :team="team" @close="dynamicShow = false" />

    <PlusTeamModal
      v-if="teamModalVisible"
      :visible="teamModalVisible"
      :type="modalType"
      :team="team"
      ref="PlusTeamModal"
      @close="teamModalVisible = false"
      @plusOk="plusTeamOk"
      @editOk="editTeamOk"
      @addMember="handleAddMember"
    />

    <PlusMember
      v-if="memberModalVisible"
      :visible="memberModalVisible"
      :type="memberModalType"
      :team="team"
      ref="PlusMember"
      @close="memberModalVisible = false"
      @plusOk="plusMemberOk"
      @sendMember="sendMemberList"
    />

    <TransferTeamModal
      v-if="transferTeamVisible"
      :team="team"
      :visible="transferTeamVisible"
      @close="transferTeamVisible = false"
      @Ok="transferTeamOk"
    />

    <DissolveTeam
      ref="DissolveTeam"
      :mobel="mobel"
      v-if="dissolveTeamVisible"
      :team="team"
      :visible="dissolveTeamVisible"
      @close="dissolveTeamVisible = false"
      @Ok="dissolveTeamOk"
    />
    <!-- 文件夹新建 -->
    <PlusModal :plusData="plusData" @close="plusModalClose" />
  </div>
</template>
<script>
import Vue from "vue";
import MydocMenu from "@/views/mydoc/menu.vue";
import MyDocMenuItem from "@/views/mydoc/menuItem.vue";
import PlusModal from "@/components/layouts/MenuComponents/PlusModal.vue";
import MenuSimple from "@/views/mydoc/menuSimple.vue";
import Empty from "@/components/Empty/index.vue";
import FileList from "@/views/recycle/fileList.vue";
import TileList from "@/views/mydoc/tileList.vue";
import CooperationModal from "@/views/mydoc/CooperationModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";
import ShareFileModal from "@/views/mydoc/ShareFileModal.vue";
import { Radio } from "ant-design-vue";
import Popup from "@/views/popup/index.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import FileDetails from "@/components/FileDetails/index.vue";
import TeamDynamic from "./TeamDynamic.vue";
import PlusTeamModal from "./PlusTeamModal.vue";
import PlusMember from "./PlusMember.vue";
import TransferTeamModal from "./TransferTeamModal.vue";
import DissolveTeam from "./DissolveTeam.vue";
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "@/views/mydoc/mixin/index";
import actions from "../json/companyAction";
import actionsForDefault from "../json/companyActionDefault";
const mobel = JSON.parse(localStorage.pro__Login_Userinfo)?.value?.phone;
export default Vue.extend({
  name: "TeamDocument",
  mixins: [Mixins],
  components: {
    "a-radio": Radio,
    MydocMenu,
    MyDocMenuItem,
    MenuSimple,
    Popup,
    Empty,
    FileList,
    PlusModal,
    TileList,
    PreviewImg,
    FileDetails,
    CooperationModal,
    ShareModal,
    ShareFileModal,
    TeamDynamic,
    PlusTeamModal,
    PlusMember,
    TransferTeamModal,
    DissolveTeam,
  },
  props: {},
  data() {
    return {
      mobel,
      // 新建数据
      plusData: {
        visible: false,
        addType: "",
        title: "",
        formModel: {
          value: "",
        },
      },
      checkIdListFlag: false,
      indeterminate: false,
      UploaderList: [
        {
          icon: "shangchuanwenjian",
          title: "上传文件",
          functionCode: "UPLOAD_LOCAL_FILE",
          emit: this.handleUpload,
        },
        {
          icon: "shangchuanwenjianjia",
          title: "上传文件夹",
          functionCode: "UPLOAD_LOCAL_DIR",
          emit: this.handleUploadFolder,
        },
      ],

      file_option: [
        {
          label: "下载",
          onClick: () => {
            const that = this;
            that.handleClick({ func: "download" });
          },
        },
        {
          label: "复制",
          onClick: () => {
            const that = this;
            that.handleClick({ func: "copy" });
          },
        },
        {
          label: "移动",
          onClick: () => {
            const that = this;
            that.handleClick({ func: "move" });
          },
        },
        {
          label: "删除",
          onClick: () => {
            const that = this;
            that.handleClick({ func: "delete" });
          },
        },
      ],
      teamId: "",
      actions,
      actionsForDefault,
      // 团队弹窗类型
      modalType: "view",
      // 团队成员添加/编辑
      memberModalType: "add",
      // 团队动态
      dynamicShow: false,
      // 团队弹窗
      teamModalVisible: false,
      // 成员
      memberModalVisible: false,
      // 团队转让
      transferTeamVisible: false,
      // 团队解散
      dissolveTeamVisible: false,
    };
  },
  computed: {
    // 是否开启了审核
    isAudit: function () {
      return this.$store.state.user.isAudit;
    },
    teamList() {
      return this.$store.state.team.teamList;
    },
    teamName() {
      return this.teamList.find((i) => i.id === this.teamId)?.teamName;
    },
    team() {
      const teamData = this.teamList.find(
        (i) => i.id === this.$route.query.teamId
      );
      return teamData || {};
    },
  },
  watch: {
    "$route.query": {
      handler() {
        this.teamId = this.$route.query.teamId;
        if (this.$route.query.refreshFlag) {
          // this.ViewInit()
          // alert('989')
        }
      },
      deep: true,
    },
    checkIdList(v) {
      this.indeterminate =
        Boolean(v.length) && v.length !== this.listData.length;
      this.checkIdListFlag = Boolean(
        v.length === this.listData.length && this.listData.length
      );
    },
    "team.teamName": {
      handler(v) {
        this.breadcrumbList.splice(0, 1, {
          text: v,
          id: this.$route.query.teamId,
        });
        console.log(this.breadcrumbList);
      },
      deep: true,
    },
  },
  methods: {
    // 创建书写md文档
    createMdFile() {
      console.log("个人文档创建书写markdown文件");
      this.$router.push({
        name: "MD_EDITOR",
        query: {
          type: "creat",
          teamId: this.$route.query.teamId ? this.$route.query.teamId : null,
          parentId: this.$route.query.id ? this.$route.query.id : "",
        },
      });
    },

    formatUserFilePath(pathStr) {
      if (pathStr === "/") {
        return this.teamName;
      }
      const parts = pathStr.split("/");
      parts.shift(); // 去掉第一个空字符串（如果存在）
      return this.teamName + "/" + parts.join("/");
    },

    // 文档工具
    showDocToolByFile(eventType) {
      console.log("选中的文件为-------", this.checkedFileItemList);
      let filterEditType = [
        "pdf_edit_addImage",
        "pdf_edit_brush",
        "pdf_edit_signature",
        "pdf_edit_watermark",
      ];
      if (filterEditType.includes(eventType)) {
        // alert('编辑功能开发中')
        this.$router.push({
          name: "PDF_EDIT",
          query: {
            id: this.checkedFileItemList[0].id,
            activeMenu: eventType,
            userFileName: encodeURIComponent(
              this.checkedFileItemList[0].userFileName
            ),
          },
        });
        return;
      }

      let tempCheckedFileItemList = this.checkedFileItemList.map((item) => {
        return {
          ...item,
          actionFilePath: this.formatUserFilePath(item.userFilePath),
        };
      });

      this.$store.commit("docTool/changeDocToolFrom", "fileMenu"); // 改变文档工具进入来源
      this.$store.commit("docTool/addSelectFiles", tempCheckedFileItemList);
      this.$store.dispatch("docTool/async_setActiveMenu", eventType);
      setTimeout(() => {
        this.$store.dispatch(
          "docTool/async_changeDocTransformDialogFlag",
          true
        ); // 打开文档转换窗口/
      }, 100);
      this.$store.dispatch(
        "docTool/async_setShowDocToolMinimizedFloatingWindow",
        false
      ); // 关闭文档转换最小化悬浮窗口
    },

    plusModalClose() {
      this.plusData = this.$options.data().plusData;
    },
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.checkIdList = this.listData.map((i) => i.id);
      } else {
        this.checkIdList = [];
      }
    },
    handleAddFile(type) {
      if (type === "folder") {
        this.plusData = {
          title: "新建文件夹",
          addType: type,
          visible: true,
          formModel: { value: "" },
        };
      } else {
        this.plusData = {
          title: type.title,
          addType: type,
          visible: true,
          formModel: { value: "" },
        };
      }
    },
    // 点击上传文件
    handleUpload() {
      let id = this.$route.query.id || "";

      this.$store.commit("upload/SET_UPLOADCLICK", id);
    },
    // 点击上传文件夹
    handleUploadFolder() {
      let id = this.$route.query.id || "";
      console.log("点击上传文件夹--id", id);
      // this.$store.commit("upload/setUploadClickFolder", id);
      this.$store.commit("upload/SET_UPLOADCLICKFOLDER", id);
    },
    // 页面初始化
    ViewInit() {
      this.moduleType = "team";
      this.routeName = this.$route.name;
      this.teamId = this.$route.query.teamId;
    },
    dataInit() {
      let id = this.$route.query.id || "";
      this.fetchData(id).then(() => {
        // 如果有传fileId, 默认选中
        let fileId = this.$route.query.fileId;
        this.checkIdList = fileId ? [fileId] : [];
      });
      // 获取面包屑
      this.setBreadcrumb(id, { text: this.teamName || "我的团队", id: "" });
    },
    fetchData(id) {
      this.wrapLoading = true;
      return this.$apis
        .companyFilePageList({
          id,
          teamId: this.$route.query.teamId,
          isPage: 0,
        })
        .then((res) => {
          this.setData({ list: res.data.list });
          return Promise.resolve();
        })
        .finally(() => {
          this.wrapLoading = false;
        });
    },
    // 去到回收站
    handleRecycle() {
      this.$router.push({
        name: "VIEW_RECYCLE_BIN",
        query: { teamId: this.teamId },
        params: { teamName: this.teamName },
      });
    },
    handleDetail() {
      this.dynamicShow = false;
      this.detailShow = true;
    },
    handleAction(action) {
      console.log("action", action);
      switch (action) {
        case "dynamic":
          this.detailShow = false;
          this.dynamicShow = true;
          break;
        case "view":
          this.modalType = "view";
          this.teamModalVisible = true;
          break;
        case "exit":
          const that = this;
          this.$confirm({
            title: "退出团队?",
            content:
              "退出团队后，你将不能在编辑和访问该团队内的文件，确定要退出吗？",
            okText: "是",
            okType: "danger",
            cancelText: "否",
            onOk() {
              that.$apis.companyTeamExit({ id: that.teamId }).then((res) => {
                if (res.code === "200") {
                  that.$message.success("退出团队成功");
                  that.$store.dispatch(
                    "team/GET_TEAMLIST",
                    that.$store.state.user.companyId
                  );
                  that.$router.push({ path: "/star/companyTeam/table" });
                }
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
          break;
        case "edit":
          this.modalType = "edit";
          this.teamModalVisible = true;
          break;
        case "addMember":
          this.memberModalType = "add";
          this.memberModalVisible = true;
          break;
        case "transfer":
          this.transferTeamVisible = true;
          break;
        case "dissolve":
          this.dissolveTeamVisible = true;
          break;
        default:
          break;
      }
    },
    // 团队新建确认
    plusTeamOk(params) {
      // alert('9999')
      return;
      this.$apis
        .companyTeamAddTeam(params)
        .then((res) => {
          if (res.code === "200") {
            this.$message.success("新建团队成功");
            this.teamModalVisible = false;
            this.$store.dispatch(
              "team/GET_TEAMLIST",
              this.$store.state.user.companyId
            );
          }
        })
        .catch((err) => {
          // alert('888')
          this.teamModalVisible = false;
          console.log("🚀 新建团队失败:", err);
        });
    },
    // 团队编辑确认
    editTeamOk(params) {
      this.$apis.companyTeamRename(params).then((res) => {
        if (res.code === "200") {
          this.$message.success("编辑团队成功");
          this.teamModalVisible = false;
          this.$store.dispatch(
            "team/GET_TEAMLIST",
            this.$store.state.user.companyId
          );
        }
      });
    },
    // 添加成员
    handleAddMember(team, memberList) {
      console.log(team, memberList);
      this.memberModalType = "edit";
      this.memberModalVisible = true;
      this.$nextTick(() => {
        this.$refs.PlusMember.setMemberList(memberList);
      });
    },
    // 成员添加确认
    plusMemberOk(params) {
      this.$apis.addTteaMembermHttp(params).then((res) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
          this.memberModalVisible = false;
          this.$store.dispatch(
            "team/GET_TEAMLIST",
            this.$store.state.user.companyId
          );
        }
      });
    },
    // 发送成员列表到编辑页面
    sendMemberList(memberList) {
      this.$refs.PlusTeamModal.setMemberList(memberList);
      this.memberModalVisible = false;
    },
    // 团队转让确认
    transferTeamOk(params) {
      this.$apis.companyTeamTransfer(params).then((res) => {
        if (res.code === "200") {
          this.$message.success("转让团队成功");
          this.transferTeamVisible = false;
          this.$store.dispatch(
            "team/GET_TEAMLIST",
            this.$store.state.user.companyId
          );
        }
      });
    },
    // 团队解散确认
    dissolveTeamOk(params) {
      this.$apis.companyTeamDisband(params).then(
        (res) => {
          if (res.code === "200") {
            this.$message.success("解散团队成功");
            this.dissolveTeamVisible = false;
            this.$store.dispatch(
              "team/GET_TEAMLIST",
              this.$store.state.user.companyId
            );
            this.$router.push({ path: "/star/companyTeam/table" });
          }
        },
        () => {
          this.$refs.DissolveTeam.OkLoading = false;
        }
      );
    },
  },
  created() {
    // 默认设置为平铺
    this.handleClick({
      func: "conversionMode",
      name: "pingpushitu",
      text: "平铺视图",
      _value: "pingpushitu",
    });

    console.log("有默认团队吗:", this.team);
  },
  mounted() {
    let docToolMenuArr = [
      "PDF_Word",
      "Word_PDF",
      "Excel_PDF",
      "PPT_PDF",
      "pdf_edit_addImage",
      "pdf_edit_brush",
      "pdf_edit_signature",
      "pdf_edit_watermark",
    ];

    docToolMenuArr.forEach((eventType) => {
      this.$on(eventType, (el) => {
        // this.setDocToolParams=eventType
        console.log("团队菜单点击的是", eventType);
        this.showDocToolByFile(eventType); // 打开文档转换窗口/
      });
    });
  },
});
</script>

<style scoped lang="less">
@import url("/src/views/mydoc/index.less");

.ant-dropdown-link {
  margin: 12px 0 24px;
  display: flex;
  align-items: center;
}

.recycle {
  line-height: 32px;
  margin-right: 20px;

  span {
    color: #4a61ff;
  }
}

.danger {
  color: #f52f3e;
}

.set-icon {
  width: 20px !important;
  height: 20px !important;
  margin-left: 8px;
}

.titles {
  width: 100%;
}

::v-deep .icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.breadcrumb {
  // height: 156px;\
  margin-bottom: 24px;

  img {
    width: 32px;
    cursor: pointer;

    :nth-child(1) {
      width: 36px;
    }
  }
}

.ckeckbread {
  color: #3271ff;
  font-weight: 500;
}
</style>
